<script>
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

  export default {
    name: 'Registration',
    metaInfo: {
      title: 'Регистрация',
    },
    data () {
      return {
        show: false,
        show2: false,
        // loading: false,
        valid: false,
        // name: 'Иван',
        // last_name: 'Иванов',
        // phone: {
        //   number: '',
        //   valid: false,
        //   country: undefined,
        // },
        // email: 'qwe@qwe.ru',
        // password: 'qweqwe',
        // confirmPassword: 'qweqwe',
        // promocode: 'promocode',
        // nickname: 'nickname',
        name: '',
        last_name: '',
        phone: {
          number: '',
          valid: false,
          country: undefined,
        },
        email: '',
        password: '',
        confirmPassword: '',
        promocode: '',
        nickname: '',
        emailRules: [
          v => !!v || 'Эл. почта обязательна',
          v => emailRegex.test(v) || 'Неверный формат',
        ],
        passwordRules: [
          v => !!v || 'Пароль обязателен',
          v => (v && v.length >= 6) || 'Пароль должен быть больше и равен 6-ти символам',
          // v => (v.length && v === this.confirmPassword) || 'Пароли должны совпадать',
        ],
        confirmPasswordRules: [
          v => !!v || 'Пароль обязателен',
          v => v === this.password || 'Пароли должны совпадать',
        ],
        phoneRules: [
          v => (v && !!this.phone.valid) || 'Неверный телефон',
        ],
      }
    },
    computed: {
      loading () {
        // console.log(this.$store.getters.loading)
        return this.$store.getters.loading
      },
    },
    methods: {
      onPhoneInput (formattedNumber, { number, valid, country }) {
        this.phone.number = number.international
        this.phone.valid = valid
        this.phone.country = country && country.name
      },

      onSubmit () {
        if (this.$refs.form.validate()) {
          const user = {
            email: this.email,
            password: this.password,
            phone: this.phone.number,
          }

          console.log(user)

          // this.$store.dispatch('registerUser', user)
          //   .then(() => {
          //     this.$router.push('/')
          //   })
          //   .catch(err => console.log(err))
        }
      },
    },
  }
</script>

<template>
  <div class="page-wrap v-application v-application--is-ltr">
    <div class="session-form-hold">
      <base-card>
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          top
          color="primary"
        />
        <v-card-text class="text-center">
          <v-img
            src="/img/logo_full.png"
            width="100"
            class="mb-4"
          />
          <h6 class="text--disabled font-weight-medium mb-10">
            Регистрация
          </h6>
          <v-form
            ref="form"
            v-model="valid"
            validation
          >
            <v-text-field
              v-model="name"
              label="Имя"
              required
              :rules="[v => !!v || 'Обязательно к заполнению']"
            />
            <v-text-field
              v-model="last_name"
              label="Фамилия"
            />
            <vue-tel-input-vuetify
              name="phone"
              label=""
              placeholder="+79291234567"
              :clearable="true"
              select-label="Ваш телефон"
              :default-country="'ru'"
              :only-countries="['ua', 'ru']"
              :rules="phoneRules"
              @input="onPhoneInput"
            />
            <v-text-field
              v-model="email"
              label="Эл. почта"
              :rules="emailRules"
              required
            />

            <v-text-field
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-2"
              label="Пароль"
              :rules="passwordRules"
              @click:append="show = !show"
            />

            <v-text-field
              v-model="confirmPassword"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              name="password_confirmation"
              label="Повторите пароль"
              :rules="confirmPasswordRules"
              @click:append="show2 = !show2"
            />

            <v-text-field
              v-model="promocode"
              label="Промокод"
            />

            <v-text-field
              v-model="nickname"
              label="Ник"
              required
              :rules="[v => !!v || 'Обязательно к заполнению']"
            />

            <v-btn
              class="mb-4"
              block
              color="primary"
              :disabled="!valid || loading"
              :loading="loading"
              @click="onSubmit"
            >
              <v-icon left>
                mdi-account-plus
              </v-icon>
              Регистрация
            </v-btn>
            <div class="d-flex justify-center flex-wrap">
              <v-btn
                text
                small
                exact
                color="primary"
                :to="{ name: 'Логин'}"
              >
                Форма входа
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page-wrap {
  //background-color: #242939 !important;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
