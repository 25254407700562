import Vuex from 'vuex'
import Vue from 'vue'
// import chat from './modules/chat'
import themeConfig from './modules/themeConfig'
// import authData from './modules/authData'
// import invoice from './modules/invoice'
// import cart from './modules/cart'
import verticalSidebar from './modules/verticalSidebar'
// import scrumboard from './modules/scrumboard'

// weport
import user from './modules/user'
import common from './common'

// Load Vuex
Vue.use(Vuex)

// Create store
export default new Vuex.Store({
  modules: {
    // largeSidebar,
    // compactSidebar,
    // chat,
    themeConfig,
    // authData,
    // invoice,
    // cart,
    verticalSidebar,
    // scrumboard,
    user,
    common,
  },
})
