/*
 * ============================
 * File: index.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Saturday, 18th April 2020 9:32:52 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

// Imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import common from '@/store/common'
// import auth from '@/store/auth'

// weport
import Registration from '@/components/Auth/Registration'
import Login from '@/components/Auth/Login'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  // beforeEnter: common.actions.checkAuth(),
  // beforeEnter: auth,
  // beforeEnter: (to, from, next) => {
  //   alert(1)
  // },
  routes: [
    {
      path: '/registration',
      name: 'Регистрация',
      component: Registration,
    },
    {
      path: '/login',
      name: 'Авторизация',
      component: Login,
    },
    {
      path: '/',
      name: 'Домой',
      component: () => import('@/pages/app/Index'),
      // redirect: '/app/dashboard/analytic',

      children: [
        {
          path: '/app/cab',
          name: 'Личный кабинет',
          component: () => import('@/pages/app/cab/Index'),
          meta: {
            link: false,
          },

          children: [
            {
              path: 'profile',
              name: 'Мой профиль',
              component: () => import('@/pages/app/cab/Profile'),
            },
            {
              path: 'groups',
              name: 'Компании и группы',
              // meta: {
              //   breadcrumb: [{ name: 'Кабинет' }, { name: 'Компании и группы' }],
              // },
              component: () => import('@/pages/app/cab/Groups'),
            },
          ],
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  // console.log([to, from, next])
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    store.dispatch('changeThemeLoadingState', true)
  }
  // next()

  // auth
  const isAuthenticated = common.actions.checkAuth()

  if (!isAuthenticated) {
    if (
      to.name === 'Авторизация'
      || to.name === 'Регистрация'
    ) {
      next()
    }
    else {
      next({ name: 'Авторизация' })
    }
  }
  // already authed
  else {
    next()
  }
})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  setTimeout(() => store.dispatch('changeThemeLoadingState', false), 500)
  // store.dispatch('changeThemeLoadingState', false)
})

export default router
