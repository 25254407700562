<script>
  // import { mapGetters, mapActions } from 'vuex'

  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

  export default {
    name: 'Login',
    metaInfo: {
      title: 'Авторизация',
    },
    data () {
      return {
        show: false,
        loading: false,
        valid: false,
        email: 'admin@weport.ru',
        password: '123456',
        emailRules: [
          v => !!v || 'Емэйл обязателен',
          v => emailRegex.test(v) || 'Емэйл должен быть корректным',
        ],
        passwordRules: [
          v => !!v || 'Пароль обязателен',
          v => (v && v.length >= 6) || 'Пароль должен быть больше и равен 6-ти символам',
        ],
      }
    },
    methods: {
      onSubmit () {
        if (this.$refs.form.validate()) {
          const user = {
            email: this.email,
            password: this.password,
          }

          console.log(user)
        }
      },
    },
  }
</script>

<template>
  <div class="page-wrap v-application v-application--is-ltr">
    <div class="session-form-hold">
      <base-card>
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          top
          color="primary"
        />
        <v-card-text class="text-center">
          <v-img
            src="/img/logo_full.png"
            width="100"
            class="mb-4"
          >
          </v-img>
          <h6 class="text--disabled font-weight-medium mb-10">
            Авторизоваться
          </h6>
          <v-form
            ref="form"
            v-model="valid"
            validation
          >
            <v-text-field
              v-model="email"
              label="Эл. почта"
              :rules="emailRules"
              required
            />

            <v-text-field
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-2"
              label="Пароль"
              :rules="passwordRules"
              @click:append="show = !show"
            />
            <!--            <v-checkbox-->
            <!--              v-model="checkbox1"-->
            <!--              label="Remember this computer"-->
            <!--            />-->
            <v-btn
              class="mb-4"
              block
              color="primary"
              :disabled="!valid || loading"
              @click="onSubmit"
            >
              <v-icon left>
                mdi-login
              </v-icon>
              Войти
            </v-btn>
            <div class="d-flex justify-center flex-wrap">
              <!--              <v-btn-->
              <!--                text-->
              <!--                small-->
              <!--                color="primary"-->
              <!--                class="mb-2"-->
              <!--              >-->
              <!--                Забыли пароль?-->
              <!--              </v-btn>-->
              <v-btn
                text
                small
                color="primary"
                :to="{ name: 'Регистрация'}"
              >
                Регистрация
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page-wrap {
  //background-color: #242939 !important;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
